import React from 'react'
import { Link } from 'gatsby'
export default function Navbar() {
    return (
    <nav className="flex flex-col backgroundColor text-center sm:flex-row sm:text-left sm:justify-between py-3 px-6 sm:items-baseline w-full text-white">
        <link rel="stylesheet" href="https://use.typekit.net/ycp7dmd.css"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&@400&display=swap" rel="stylesheet"/>
            <div clasName="mb-2 sm:mb-0">
                <Link className='text-2xl no-underline text-white hoverLink' style={{margin: "auto"}} to='/'>
                    <img style={{margin: "auto"}}src="https://www.datocms-assets.com/56195/1633640923-nom-logo-header.svg"></img>
                </Link>
            </div>
        <div className='mt-3 sm:m-0'>
            <Link style={{fontFamily: "Josefin sans, sans-serif", textTransform: "uppercase"}} className='text-lg sm:p-0 no-underline ml-2 text-white hoverLink' to='/portfolio/'>Portfolio</Link>
        </div>
  </nav>
    )

}
