import React from "react"
import { Helmet } from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"
  

const SEO = ({title, description}) => {
    // const { site } = useStaticQuery(
    //     graphql`
    //       query {
    //         site {
    //           siteMetadata {
    //             title
    //             description
    //             url
    //           }
    //         }
    //       }
    //     `
    //   )
    return (
      <Helmet htmlAttributes={{ lang: `en` }}
      titleTemplate={`${title}`}
      >
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
  )
}

export default SEO